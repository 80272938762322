@import '../../../../estee_base/scss/theme-bootstrap';

.lc-service-drawer {
  &-v1 {
    #{$rdirection}: 0;
    background: $color-white;
    bottom: 0;
    box-shadow: 0 0 4px $color-navy;
    display: none;
    position: fixed;
    width: 100%;
    z-index: 99999;
    @media #{$medium-up} {
      #{$rdirection}: 5px;
      bottom: calc(60px + 4em);
      width: 400px;
    }
  }
  &__header-container {
    background: $color-navy;
    padding: 19px 15px 18px;
  }
  &__header-text {
    color: $color-white;
    float: #{$ldirection};
    font-size: 25px;
    font-weight: bold;
    margin: 0;
    text-transform: inherit;
  }
  &__button {
    background: $color-navy;
    color: $color-white;
    cursor: pointer;
    display: block;
    font-size: 15px;
    margin: 15px 0;
    padding: 15px 10px;
    text-align: center;
    width: 100%;
  }
  &__body-container {
    font-weight: bold;
    padding: 30px;
    color: $color-black;
  }
  &__logo {
    background: url('/sites/esteelauder/themes/estee_base/img/sprites/elc-logo-small-alt.png');
    background-size: 166px 23px;
    fill: $color-white;
    float: #{$rdirection};
    height: 23px;
    width: 166px;
  }
  &__overlay {
    display: none;
    position: fixed;
    top: 0;
    @media #{$device-tablet-down} {
      height: 100%;
      width: 100%;
      z-index: 9990;
    }
  }
}

.rounded-live-chat-button {
  #{$rdirection}: 5px;
  align-items: center;
  background-color: $color-navy;
  border: 2px solid $color-navy;
  border-radius: 2em;
  bottom: 60px;
  color: $color-white;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  justify-content: center;
  padding: 1em;
  position: fixed;
  z-index: 9998;
  &__down-charot {
    border-#{$rdirection}: 2px solid $color-white;
    border-bottom: 2px solid $color-white;
    height: 0.5em;
    margin: 0 10px 5px;
    transform: rotate(45deg);
    width: 0.5em;
  }
}

.LPMcontainer {
  visibility: hidden;
}

.is-sticky {
  .page-navigation {
    .lc-service-drawer-v1 {
      display: none !important;
    }
  }
}
